/* eslint-disable react/prop-types */
import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles

// import Swiper core and required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import Image from 'next/image' // install Swiper modules

const ImagePart = ({ images }) => {
  return (
    <div className=" ">
      {images ? (
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Navigation, Autoplay, Pagination]}
        >
          {images?.map((image, i) => {
            return (
              <SwiperSlide key={i} className="">
                <div className="   ">
                  <img
                    src={image}
                    alt="details image"
                    layout="fill"
                    objectFit="cover"
                    priority={true}
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      ) : (
        <div className="w-[400px] h-[400px] lg:w-[500px] lg:h-[500px] xl:w-[600px] xl:h-[600px] bg-[#3B3269] bg-opacity-[10%] animate-pulse"></div>
      )}
    </div>
  )
}

export default ImagePart
