import product1_image from '@/img/tradly/air-jordan-1-mid-se-shoes-hHltxp.jpeg'
import account1_image from '@/img/tradly/account1.png'
import event1_image from '@/img/tradly/productconference.jpg'
import eventV1_image from '@/img/tradly/nordic design.jpg'
import group1_image from '@/img/tradly/Turkish Dried Fruits.jpeg'
import group2_image from '@/img/tradly/Yemen coffee.jpeg'
import group3_image from "@/img/tradly/Organic Africa's Veg.jpg"
import group4_image from '@/img/tradly/Nordic Flowers.jpeg'

module.exports = {
  product: {
    listing: {
      id: 9883,
      account_id: 2714,
      list_price: {
        amount: 80,
        currency: 'AUD',
        formatted: 'A$ 80',
      },
      category_id: [73],
      currency_id: 9,
      offer_percent: 0,
      stock: 300,
      shipping_charges: {
        amount: 100,
        currency: 'AUD',
        formatted: 'A$ 100',
      },
      images: [product1_image],
      offer_price: {
        amount: 15,
        currency: 'AUD',
        formatted: 'A$15',
      },
      location: {
        city: 'Dereel',
        state: 'Victoria',
        country: 'Australia',
        locality: 'Essendon',
        postcode: '3040',
        country_code: 'au',
        formatted_address: '67 Sunraysia Road,  Dereel, Victoria, Australia',
      },
      type: 'listings',
      sold: false,
      active: true,
      status: 2,
      created_at: 1640139333,
      start_at: 1640139333,
      end_at: 1640139333,
      likes: 1,
      max_quantity: 300,
      account: {
        id: 2714,
        name: 'Jay Z shop',
        location: {
          city: 'Dereel',
          state: 'Victoria',
          country: 'Australia',
          locality: 'Essendon',
          postcode: '3040',
          country_code: 'au',
          formatted_address: '67 Sunraysia Road,  Dereel, Victoria, Australia',
        },
        unique_name: '',
        description: '@monrose.indoor.plants on Instagram or www.Monroseindoorplants.com.au',
        total_followers: 22,
        total_listings: 49,
        images: [account1_image, account1_image],
        type: 'accounts',
        active: true,
        status: 2,
        following: false,
        user: {
          email_verified: true,
          blocked: false,
          id: '6daa9f01-8540-4c2f-a886-6bc36ac1bb74',
          first_name: 'Monrose',
          last_name: 'Indoor Plants',
          profile_pic:
            'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/64375/d7a90962-c1ea-4ddd-9424-3cf0529fa173.png',
          email: 'nikitat484@gmail.com',
          mobile: '',
          dial_code: '',
          created_at: 1630452449,
          updated_at: 1630628078,
        },
        category_id: [],
      },
      categories: [
        {
          id: 73,
          parent: 0,
          type: 'listings',
          active: true,
          image_path:
            'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/67018/28edeebc-3402-428e-921c-850b09afb1fb.png',
          order_by: 5,
          sub_category: [],
          name: 'Pots & Accessories',
          hierarchy: [
            {
              id: 73,
              name: 'Pots & Accessories',
              level: 1,
            },
          ],
        },
      ],
      distance: 0,
      in_cart: false,
      liked: false,
      title: 'AIR JORDAN 1 MID SE',
      description: '',
      coordinates: {
        latitude: -37.755963,
        longitude: 144.91617790401142,
      },
      attributes: [
        {
          id: 8,
          type: 'listings',
          field_type: 1,
          optional: false,
          private: false,
          active: true,
          order_by: 0,
          name: 'Posts to Quarantine States?',
          categories: [
            {
              id: 65,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/20384/78a1e20a-7700-4459-b61d-5236f1d9e49b.jpg',
              order_by: 1,
              sub_category: [],
              name: 'Small',
              hierarchy: [
                {
                  id: 65,
                  name: 'Small',
                  level: 1,
                },
              ],
            },
            {
              id: 66,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/70705/769b998b-f4a5-4858-b52f-bebbfac2736f.png',
              order_by: 2,
              sub_category: [],
              name: 'Large',
              hierarchy: [
                {
                  id: 66,
                  name: 'Large',
                  level: 1,
                },
              ],
            },
            {
              id: 72,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/27574/fd36f4b6-52e7-4d8a-ab55-f430af15323a.png',
              order_by: 3,
              sub_category: [],
              name: 'Cuttings',
              hierarchy: [
                {
                  id: 72,
                  name: 'Cuttings',
                  level: 1,
                },
              ],
            },
            {
              id: 70,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/10089/82c7a87c-0ccc-4bbf-b5d5-e32ba6c05631.png',
              order_by: 4,
              sub_category: [],
              name: 'Cacti & Succulents',
              hierarchy: [
                {
                  id: 70,
                  name: 'Cacti & Succulents',
                  level: 1,
                },
              ],
            },
            {
              id: 73,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/67018/28edeebc-3402-428e-921c-850b09afb1fb.png',
              order_by: 5,
              sub_category: [],
              name: 'Pots & Accessories',
              hierarchy: [
                {
                  id: 73,
                  name: 'Pots & Accessories',
                  level: 1,
                },
              ],
            },
            {
              id: 75,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/38943/653a0f8d-eee6-4b8b-918d-1c9e720576c1.png',
              order_by: 8,
              sub_category: [],
              name: 'Unicorn (Rarer Plants)',
              hierarchy: [
                {
                  id: 75,
                  name: 'Unicorn (Rarer Plants)',
                  level: 1,
                },
              ],
            },
            {
              id: 76,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/41769/dc1105f2-26be-4e21-b40e-88da631e7496.png',
              order_by: 8,
              sub_category: [],
              name: 'Climbers',
              hierarchy: [
                {
                  id: 76,
                  name: 'Climbers',
                  level: 1,
                },
              ],
            },
            {
              id: 69,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/94603/2ccee3b6-9411-4389-842b-600713b6459f.png',
              order_by: 9,
              sub_category: [],
              name: 'Low Light',
              hierarchy: [
                {
                  id: 69,
                  name: 'Low Light',
                  level: 1,
                },
              ],
            },
            {
              id: 77,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/72936/cf235659-66e3-4589-b73c-70a41b1e1a49.png',
              order_by: 10,
              sub_category: [],
              name: 'Low Maintenance ',
              hierarchy: [
                {
                  id: 77,
                  name: 'Low Maintenance ',
                  level: 1,
                },
              ],
            },
          ],
          values: [
            {
              id: 13,
              active: true,
              name: 'Yes',
            },
          ],
        },
        {
          id: 46,
          type: 'listings',
          field_type: 1,
          optional: false,
          private: false,
          active: true,
          order_by: 0,
          name: 'Postage available?',
          categories: [
            {
              id: 65,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/20384/78a1e20a-7700-4459-b61d-5236f1d9e49b.jpg',
              order_by: 1,
              sub_category: [],
              name: 'Small',
              hierarchy: [
                {
                  id: 65,
                  name: 'Small',
                  level: 1,
                },
              ],
            },
            {
              id: 66,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/70705/769b998b-f4a5-4858-b52f-bebbfac2736f.png',
              order_by: 2,
              sub_category: [],
              name: 'Large',
              hierarchy: [
                {
                  id: 66,
                  name: 'Large',
                  level: 1,
                },
              ],
            },
            {
              id: 72,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/27574/fd36f4b6-52e7-4d8a-ab55-f430af15323a.png',
              order_by: 3,
              sub_category: [],
              name: 'Cuttings',
              hierarchy: [
                {
                  id: 72,
                  name: 'Cuttings',
                  level: 1,
                },
              ],
            },
            {
              id: 70,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/10089/82c7a87c-0ccc-4bbf-b5d5-e32ba6c05631.png',
              order_by: 4,
              sub_category: [],
              name: 'Cacti & Succulents',
              hierarchy: [
                {
                  id: 70,
                  name: 'Cacti & Succulents',
                  level: 1,
                },
              ],
            },
            {
              id: 73,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/67018/28edeebc-3402-428e-921c-850b09afb1fb.png',
              order_by: 5,
              sub_category: [],
              name: 'Pots & Accessories',
              hierarchy: [
                {
                  id: 73,
                  name: 'Pots & Accessories',
                  level: 1,
                },
              ],
            },
            {
              id: 75,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/38943/653a0f8d-eee6-4b8b-918d-1c9e720576c1.png',
              order_by: 8,
              sub_category: [],
              name: 'Unicorn (Rarer Plants)',
              hierarchy: [
                {
                  id: 75,
                  name: 'Unicorn (Rarer Plants)',
                  level: 1,
                },
              ],
            },
            {
              id: 76,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/41769/dc1105f2-26be-4e21-b40e-88da631e7496.png',
              order_by: 8,
              sub_category: [],
              name: 'Climbers',
              hierarchy: [
                {
                  id: 76,
                  name: 'Climbers',
                  level: 1,
                },
              ],
            },
            {
              id: 69,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/94603/2ccee3b6-9411-4389-842b-600713b6459f.png',
              order_by: 9,
              sub_category: [],
              name: 'Low Light',
              hierarchy: [
                {
                  id: 69,
                  name: 'Low Light',
                  level: 1,
                },
              ],
            },
            {
              id: 77,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/72936/cf235659-66e3-4589-b73c-70a41b1e1a49.png',
              order_by: 10,
              sub_category: [],
              name: 'Low Maintenance ',
              hierarchy: [
                {
                  id: 77,
                  name: 'Low Maintenance ',
                  level: 1,
                },
              ],
            },
          ],
          values: [
            {
              id: 166,
              active: true,
              name: 'Yes, for a fee',
            },
          ],
        },
        {
          id: 47,
          type: 'listings',
          field_type: 1,
          optional: false,
          private: false,
          active: true,
          order_by: 0,
          name: 'Pickup available? ',
          categories: [
            {
              id: 65,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/20384/78a1e20a-7700-4459-b61d-5236f1d9e49b.jpg',
              order_by: 1,
              sub_category: [],
              name: 'Small',
              hierarchy: [
                {
                  id: 65,
                  name: 'Small',
                  level: 1,
                },
              ],
            },
            {
              id: 66,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/70705/769b998b-f4a5-4858-b52f-bebbfac2736f.png',
              order_by: 2,
              sub_category: [],
              name: 'Large',
              hierarchy: [
                {
                  id: 66,
                  name: 'Large',
                  level: 1,
                },
              ],
            },
            {
              id: 72,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/27574/fd36f4b6-52e7-4d8a-ab55-f430af15323a.png',
              order_by: 3,
              sub_category: [],
              name: 'Cuttings',
              hierarchy: [
                {
                  id: 72,
                  name: 'Cuttings',
                  level: 1,
                },
              ],
            },
            {
              id: 70,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/10089/82c7a87c-0ccc-4bbf-b5d5-e32ba6c05631.png',
              order_by: 4,
              sub_category: [],
              name: 'Cacti & Succulents',
              hierarchy: [
                {
                  id: 70,
                  name: 'Cacti & Succulents',
                  level: 1,
                },
              ],
            },
            {
              id: 73,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/67018/28edeebc-3402-428e-921c-850b09afb1fb.png',
              order_by: 5,
              sub_category: [],
              name: 'Pots & Accessories',
              hierarchy: [
                {
                  id: 73,
                  name: 'Pots & Accessories',
                  level: 1,
                },
              ],
            },
            {
              id: 75,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/38943/653a0f8d-eee6-4b8b-918d-1c9e720576c1.png',
              order_by: 8,
              sub_category: [],
              name: 'Unicorn (Rarer Plants)',
              hierarchy: [
                {
                  id: 75,
                  name: 'Unicorn (Rarer Plants)',
                  level: 1,
                },
              ],
            },
            {
              id: 76,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/41769/dc1105f2-26be-4e21-b40e-88da631e7496.png',
              order_by: 8,
              sub_category: [],
              name: 'Climbers',
              hierarchy: [
                {
                  id: 76,
                  name: 'Climbers',
                  level: 1,
                },
              ],
            },
            {
              id: 69,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/94603/2ccee3b6-9411-4389-842b-600713b6459f.png',
              order_by: 9,
              sub_category: [],
              name: 'Low Light',
              hierarchy: [
                {
                  id: 69,
                  name: 'Low Light',
                  level: 1,
                },
              ],
            },
            {
              id: 77,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/72936/cf235659-66e3-4589-b73c-70a41b1e1a49.png',
              order_by: 10,
              sub_category: [],
              name: 'Low Maintenance ',
              hierarchy: [
                {
                  id: 77,
                  name: 'Low Maintenance ',
                  level: 1,
                },
              ],
            },
          ],
          values: [
            {
              id: 168,
              active: true,
              name: 'Yes',
            },
          ],
        },
        {
          id: 56,
          type: 'listings',
          field_type: 1,
          optional: false,
          private: false,
          active: true,
          order_by: 0,
          name: 'Delivery Available? ',
          categories: [
            {
              id: 65,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/20384/78a1e20a-7700-4459-b61d-5236f1d9e49b.jpg',
              order_by: 1,
              sub_category: [],
              name: 'Small',
              hierarchy: [
                {
                  id: 65,
                  name: 'Small',
                  level: 1,
                },
              ],
            },
            {
              id: 66,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/70705/769b998b-f4a5-4858-b52f-bebbfac2736f.png',
              order_by: 2,
              sub_category: [],
              name: 'Large',
              hierarchy: [
                {
                  id: 66,
                  name: 'Large',
                  level: 1,
                },
              ],
            },
            {
              id: 72,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/27574/fd36f4b6-52e7-4d8a-ab55-f430af15323a.png',
              order_by: 3,
              sub_category: [],
              name: 'Cuttings',
              hierarchy: [
                {
                  id: 72,
                  name: 'Cuttings',
                  level: 1,
                },
              ],
            },
            {
              id: 70,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/10089/82c7a87c-0ccc-4bbf-b5d5-e32ba6c05631.png',
              order_by: 4,
              sub_category: [],
              name: 'Cacti & Succulents',
              hierarchy: [
                {
                  id: 70,
                  name: 'Cacti & Succulents',
                  level: 1,
                },
              ],
            },
            {
              id: 73,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/67018/28edeebc-3402-428e-921c-850b09afb1fb.png',
              order_by: 5,
              sub_category: [],
              name: 'Pots & Accessories',
              hierarchy: [
                {
                  id: 73,
                  name: 'Pots & Accessories',
                  level: 1,
                },
              ],
            },
            {
              id: 75,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/38943/653a0f8d-eee6-4b8b-918d-1c9e720576c1.png',
              order_by: 8,
              sub_category: [],
              name: 'Unicorn (Rarer Plants)',
              hierarchy: [
                {
                  id: 75,
                  name: 'Unicorn (Rarer Plants)',
                  level: 1,
                },
              ],
            },
            {
              id: 76,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/41769/dc1105f2-26be-4e21-b40e-88da631e7496.png',
              order_by: 8,
              sub_category: [],
              name: 'Climbers',
              hierarchy: [
                {
                  id: 76,
                  name: 'Climbers',
                  level: 1,
                },
              ],
            },
            {
              id: 69,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/94603/2ccee3b6-9411-4389-842b-600713b6459f.png',
              order_by: 9,
              sub_category: [],
              name: 'Low Light',
              hierarchy: [
                {
                  id: 69,
                  name: 'Low Light',
                  level: 1,
                },
              ],
            },
            {
              id: 77,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/wyldeplants/72936/cf235659-66e3-4589-b73c-70a41b1e1a49.png',
              order_by: 10,
              sub_category: [],
              name: 'Low Maintenance ',
              hierarchy: [
                {
                  id: 77,
                  name: 'Low Maintenance ',
                  level: 1,
                },
              ],
            },
          ],
          values: [
            {
              id: 234,
              active: true,
              name: 'Yes',
            },
          ],
        },
      ],
      tags: [],
      rating_data: {
        rating_count_data: {
          rating_1: 0,
          rating_2: 0,
          rating_3: 0,
          rating_4: 0,
          rating_5: 0,
        },
        rating_average: 0,
        rating_count: 0,
        review_count: 0,
      },
      schedules: [],
      variants: [],
    },
    rating_data: {
      rating_count_data: {
        rating_1: 0,
        rating_2: 0,
        rating_3: 0,
        rating_4: 0,
        rating_5: 0,
      },
      rating_average: 0,
      rating_count: 0,
      review_count: 0,
    },
  },
  event: {
    listing: {
      id: 9860,
      account_id: 3378,
      list_price: {
        amount: 15,
        currency: 'SGD',
        formatted: 'S$15',
      },
      category_id: [3430],
      currency_id: 22,
      offer_percent: 0,
      stock: 5,
      shipping_charges: {
        amount: 0,
        currency: 'SGD',
        formatted: 'S$0',
      },
      images: [event1_image],
      offer_price: {
        amount: 15,
        currency: 'SGD',
        formatted: 'S$15',
      },
      location: {
        city: '',
        state: '',
        country: 'Singapore',
        locality: 'Punggol',
        postcode: '828811',
        country_code: 'sg',
        formatted_address: 'Tebing Lane, Punggol, Northeast, 828811, Singapore',
      },
      type: 'events',
      sold: false,
      active: true,
      status: 2,
      created_at: 1639662894,
      start_at: 1639662894,
      end_at: 1639662894,
      likes: 0,
      max_quantity: 20,
      account: {
        id: 3378,
        name: 'Infiniti Kids',
        location: {
          city: '',
          state: '',
          country: 'Singapore',
          locality: 'Punggol',
          postcode: '828811',
          country_code: 'sg',
          formatted_address: 'Tebing Lane, Punggol, Northeast, 828811, Singapore',
        },
        unique_name: '',
        description:
          'Infiniti Kids aims to inspire children to explore and discover their potential in areas such as sports, music, art and technology so that they become confident, competent and motivated to pursue their area of interest.',
        total_followers: 0,
        total_listings: 3,
        images: [
          'https://tradly-paas.s3.amazonaws.com/images/classbubs/77393/64991909-0dcc-4478-89ed-d39b6e04dd21.JPG',
        ],
        type: 'accounts',
        active: true,
        status: 2,
        following: false,
        user: {
          email_verified: true,
          blocked: false,
          id: 'e9b6c46e-3976-45b9-8f6d-a6bf580dc49d',
          first_name: 'Infiniti',
          last_name: 'Kids',
          profile_pic:
            'https://tradly-paas.s3.amazonaws.com/images/classbubs/35346/2a9043bf-b7a2-44b2-a3e5-5fa5c248046f.JPG',
          email: 'info@infinitikids.org',
          mobile: '',
          dial_code: '',
          created_at: 1639277493,
          updated_at: 1639628165,
        },
        category_id: [],
      },
      categories: [
        {
          id: 3430,
          parent: 0,
          type: 'listings',
          active: true,
          image_path:
            'https://tradly-paas.s3.amazonaws.com/images/classbubs/52855/61b996b9-ef34-45af-bbbe-e25ab3ee4dd2.png',
          order_by: 13,
          sub_category: [],
          name: 'Sports',
          hierarchy: [
            {
              id: 3430,
              name: 'Sports',
              level: 1,
            },
          ],
        },
      ],
      distance: 0,
      in_cart: false,
      liked: false,
      title: 'Tech2Design conference',
      description:
        'About educator: Infiniti Kids aims to inspire children to explore and discover their potential in areas such as sports, music, art and technology so that they become confident, competent and motivated to pursue their area of interest.\n\nAbout class: Run, Jump, Throw! Infiniti Kids Multi Sports class aims to promote and develop Physical Literacy in young children, to develop them to be confident, competent and motivated in physical activity.\nOur program:\n\n Takes them through a variety of sports to allow sampling and exploration for kids to find\nwhat they truly enjoy!\n Consists of activities such as obstacle courses, circuits, fun games and sport skill\npractice, developing their fitness, fundamental movement skills and basic sports skills to\nprepare them for future sporting activities.\n Builds confidence and develop values in your child through social interactions with the\ncoaches and other children.\n\nJoin us for a fun and sweaty time!\n\nAge group: 3-5\n\nLocation: 6 Tebing Lane, S(828835)',
      coordinates: {
        latitude: 1.3929445,
        longitude: 103.9152788,
      },
      attributes: [
        {
          id: 50,
          type: 'listings',
          field_type: 2,
          optional: false,
          private: false,
          active: true,
          order_by: 0,
          name: 'Age Group',
          categories: [
            {
              id: 3418,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/76962/6ec255f7-1169-409e-9311-b9a5e47b3d27.png',
              order_by: 1,
              sub_category: [],
              name: 'Art',
              hierarchy: [
                {
                  id: 3418,
                  name: 'Art',
                  level: 1,
                },
              ],
            },
            {
              id: 3419,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/80711/51cc1265-bd77-487e-94b6-183c75ba61f8.png',
              order_by: 2,
              sub_category: [],
              name: 'Camps',
              hierarchy: [
                {
                  id: 3419,
                  name: 'Camps',
                  level: 1,
                },
              ],
            },
            {
              id: 3421,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/35304/7a752e63-d1ec-4a51-9d5f-6d32225ea8b6.png',
              order_by: 4,
              sub_category: [],
              name: 'Dance',
              hierarchy: [
                {
                  id: 3421,
                  name: 'Dance',
                  level: 1,
                },
              ],
            },
            {
              id: 3422,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/72463/4d73986c-a74f-4aaa-9380-3f567790ca05.png',
              order_by: 5,
              sub_category: [],
              name: 'Language',
              hierarchy: [
                {
                  id: 3422,
                  name: 'Language',
                  level: 1,
                },
              ],
            },
            {
              id: 3423,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/10946/ad3d99b6-335f-4c56-996e-abaa30e3a470.png',
              order_by: 6,
              sub_category: [],
              name: 'Life Skills',
              hierarchy: [
                {
                  id: 3423,
                  name: 'Life Skills',
                  level: 1,
                },
              ],
            },
            {
              id: 3424,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/50503/106fe09c-bb92-4a58-a368-a7b40f5fd87e.png',
              order_by: 7,
              sub_category: [],
              name: 'Music',
              hierarchy: [
                {
                  id: 3424,
                  name: 'Music',
                  level: 1,
                },
              ],
            },
            {
              id: 3425,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/71243/4fd02399-c768-48ce-bba4-e94af53e1454.png',
              order_by: 8,
              sub_category: [],
              name: 'Online Classes',
              hierarchy: [
                {
                  id: 3425,
                  name: 'Online Classes',
                  level: 1,
                },
              ],
            },
            {
              id: 3426,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/67000/a7dd0a0d-c797-4c1c-b546-039c181a4df3.png',
              order_by: 9,
              sub_category: [],
              name: 'Outdoors',
              hierarchy: [
                {
                  id: 3426,
                  name: 'Outdoors',
                  level: 1,
                },
              ],
            },
            {
              id: 3427,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/25051/45efe60e-dff2-459e-b236-c04ec59d0698.png',
              order_by: 10,
              sub_category: [],
              name: 'Parent Accompanied',
              hierarchy: [
                {
                  id: 3427,
                  name: 'Parent Accompanied',
                  level: 1,
                },
              ],
            },
            {
              id: 3428,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/24696/2bbd717d-57ba-442e-9faa-b4328d844945.png',
              order_by: 11,
              sub_category: [],
              name: 'Play',
              hierarchy: [
                {
                  id: 3428,
                  name: 'Play',
                  level: 1,
                },
              ],
            },
            {
              id: 3429,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/72451/cda6a782-4348-4d85-8dcc-c8752ef5dfe6.png',
              order_by: 12,
              sub_category: [],
              name: 'Special Workshops',
              hierarchy: [
                {
                  id: 3429,
                  name: 'Special Workshops',
                  level: 1,
                },
              ],
            },
            {
              id: 3430,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/52855/61b996b9-ef34-45af-bbbe-e25ab3ee4dd2.png',
              order_by: 13,
              sub_category: [],
              name: 'Sports',
              hierarchy: [
                {
                  id: 3430,
                  name: 'Sports',
                  level: 1,
                },
              ],
            },
            {
              id: 3431,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/58218/c6e2233c-98c7-4d00-bc55-1eb09f20a072.png',
              order_by: 14,
              sub_category: [],
              name: 'Technology',
              hierarchy: [
                {
                  id: 3431,
                  name: 'Technology',
                  level: 1,
                },
              ],
            },
            {
              id: 3432,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/65971/d466ae0e-6e5e-4ad5-b788-9923b53476e0.png',
              order_by: 15,
              sub_category: [],
              name: 'Theatre',
              hierarchy: [
                {
                  id: 3432,
                  name: 'Theatre',
                  level: 1,
                },
              ],
            },
            {
              id: 3433,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/95220/b9c31edc-34fc-4e62-b595-e81ce76e3dca.png',
              order_by: 16,
              sub_category: [],
              name: 'Wellness',
              hierarchy: [
                {
                  id: 3433,
                  name: 'Wellness',
                  level: 1,
                },
              ],
            },
            {
              id: 3434,
              parent: 0,
              type: 'listings',
              active: true,
              image_path:
                'https://tradly-paas.s3.amazonaws.com/images/classbubs/20491/021c0d5d-4505-45b3-8cb9-5ab80df101ca.png',
              order_by: 17,
              sub_category: [],
              name: 'Workshops for Parents',
              hierarchy: [
                {
                  id: 3434,
                  name: 'Workshops for Parents',
                  level: 1,
                },
              ],
            },
          ],
          values: [
            {
              id: 192,
              active: true,
              name: '3-5 years old',
            },
          ],
        },
      ],
      tags: [],
      rating_data: {
        rating_count_data: {
          rating_1: 0,
          rating_2: 0,
          rating_3: 0,
          rating_4: 0,
          rating_5: 0,
        },
        rating_average: 0,
        rating_count: 0,
        review_count: 0,
      },
      schedules: [
        {
          active: true,
          start_date: '2021-12-16',
          start_time: '01:45',
          end_time: '14:30',
          repeat_days: [7],
          schedule_type: 2,
        },
      ],
      variants: [
        {
          id: 97,
          list_price: {
            amount: 15,
            currency: 'SGD',
            formatted: 'S$15',
          },
          offer_percent: 0,
          images: [eventV1_image],
          offer_price: {
            amount: 15,
            currency: 'SGD',
            formatted: 'S$15',
          },
          active: true,
          stock: 1,
          max_quantity: 0,
          created_at: 1639662896,
          updated_at: 1639662896,
          title: 'Product Design ',
          description:
            'About educator: Infiniti Kids aims to inspire children to explore and discover their potential in areas such as sports, music, art and technology so that they become confident, competent and motivated to pursue their area of interest.\n\nAbout class: Run, Jump, Throw! Infiniti Kids Multi Sports class aims to promote and develop Physical Literacy in young children, to develop them to be confident, competent and motivated in physical activity.\nOur program:\n\n Takes them through a variety of sports to allow sampling and exploration for kids to find\nwhat they truly enjoy!\n Consists of activities such as obstacle courses, circuits, fun games and sport skill\npractice, developing their fitness, fundamental movement skills and basic sports skills to\nprepare them for future sporting activities.\n Builds confidence and develop values in your child through social interactions with the\ncoaches and other children.\n\nJoin us for a fun and sweaty time!\n\nAge group: 3-5\n\nLocation: 6 Tebing Lane, S(828835)',
          variant_values: [
            {
              variant_type_id: 1,
              variant_type_value_id: 1,
              variant_type: {
                id: 1,
                name: 'Ticket Type',
              },
              variant_type_value: {
                id: 1,
                name: 'Trial Class',
              },
            },
          ],
        },
      ],
    },
    rating_data: {
      rating_count_data: {
        rating_1: 0,
        rating_2: 0,
        rating_3: 0,
        rating_4: 0,
        rating_5: 0,
      },
      rating_average: 0,
      rating_count: 0,
      review_count: 0,
    },
  },
  group: [
    {
      name: 'Turkish Farmers',
      member: 700,
      img: group1_image,
    },
    {
      name: 'Yemen Retailers',
      member: 600,
      img: group2_image,
    },
    {
      name: 'Dutch Farmers ',
      member: 500,
      img: group3_image,
    },
    {
      name: 'Nordic Farmers',
      member: 600,
      img: group4_image,
    },
  ],
}
