import React from 'react'
import { group } from './data/ListingDetailsData'
import classes from './common.module.scss'
import Image from 'next/image'

export const group_card = (data) => {
  return (
    <div className="  md:w-[358px] min-h-[254px] bg-white shadow-lg rounded-md overflow-hidden">
      <div className=" relative w-full h-[167px]">
        <Image src={data.img} layout="fill" objectFit="cover" alt="" />
      </div>
      <div className="mt-[24px] flex flex-wrap items-center justify-between px-[22px]">
        <div>
          <h2 className=" font-semibold  text-sm md:text-base text-black">{data.name}</h2>
          <p className=" font-normal text-sm text-gray-300">{data.member} members</p>
        </div>
        <div className='mt-3 mb-3 md:mt-0 md:mb-0'>
          <button className="bg-primary py-1 px-3 rounded-md text-white text-sm  md:text-base">
            Join Group
          </button>
        </div>
      </div>
    </div>
  )
}

const Community_feature = () => {
  return (
    <div className={classes.bg_style}>
      <div className='w-full flex justify-center  '>
        <div className="py-10 grid grid-cols-2  justify-center gap-5">
          {group.map((item) => group_card(item))}
        </div>
      </div>
    </div>
  )
}

export default Community_feature
