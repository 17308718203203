import React from 'react'
import ListingCard from './ListingCard/ListingCard'
import { data } from './ListingCard/ListingsData'

const MultipleListings = () => {
  return (
    <div className='bg-white  md:p-8 rounded-lg shadow-lg '>
      <div className="grid grid-cols-2    gap-5 justify-center">
        {data?.map((item) => (
          <div key={Math.random()} className=" relative">
            <ListingCard item={item} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultipleListings
