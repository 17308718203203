/* eslint-disable react/prop-types */
import React from 'react'
import Image from 'next/image'
import favorite from '@/img/tradly/favourite@3x.png'
import heartIcon from '@/img/tradly/heartIcon@3x.png'

const ListingCard = ({ item }) => {
   return (
    <div className="  relative">
      <div>
        <a className=" block w-full  min-h-[210px] bg-[#FEFEFE]   rounded overflow-hidden cursor-pointer  shadow-lg">
          <div className="   aspect-w-1 aspect-h-1 relative  mb-4 ">
            {item?.images?.length > 0 && (
              <Image src={item?.images[0]} alt={item.title} layout="fill" objectFit="cover" />
            )}
          </div>

          <div className="mt-2 pl-2">
            <p className="  text-sm ms:text-base xmd:text-lg leading-[15px] font-semibold text-primary">
              {item.title.length > 18 ? item.title.substring(0, 18) + '..' : item.title}
            </p>
            <p className=" text-[14px]  ms:text-[16px] mb-[14px] leading-4 font-medium text-gray-500 mt-1">
              {item.list_price.formatted}
            </p>
          </div>
        </a>
      </div>

      <div className=" h-[40px] w-[40px]  absolute right-0 top-0 cursor-pointer  [z-100]  ">
        {item.liked ? (
          <Image src={favorite} alt="follow button" layout="fill" objectFit="cover" require />
        ) : (
          <Image src={heartIcon} alt="follow button" layout="fill" objectFit="cover" require />
        )}
      </div>
    </div>
  )
}

export default ListingCard
