/* eslint-disable react/prop-types */
import React from 'react'
import Image from 'next/image'
const Variants = ({
  variants,

  listing_details,
  marketplace_type,
}) => {
  const variant_title = (type) => {
    if (type === 1) {
      return 'Select Variant'
    } else {
      return 'Select Ticket'
    }
  }
  const variant_stock_text = (type, value) => {
    if (type === 1) {
      return `${value} products in stock`
    } else {
      return `${value} tickets left`
    }
  }

  return (
    <div className="bg-white rounded  w-full min-h-[66px]    p-[16px]   ">
      <p className="text-[#121212] text-sm  font-semibold leading-4 ">
        {variant_title(marketplace_type)}
      </p>
      <div className="  mt-5     ">
        {variants.map((item) => {
          return (
            <div
              key={item.id}
              className={
                'w-full min-h-[90px] relative rounded-md overflow-hidden  flex items-center my-4 shadow-c-sm  border border-transparent cursor-pointer'
              }
            >
              <div className="h-[90px] w-[90px] relative">
                {item.images.length > 0 ? (
                  <Image src={item.images[0]} layout="fill" />
                ) : (
                  <Image src={listing_details.images[0]} layout="fill" />
                )}
              </div>
              <div className=" ml-3">
                {
                  <p className=" text-sm text-primary font-normal">
                    {variant_stock_text(marketplace_type, item.stock)}
                  </p>
                }
                <p className="text-black font-semibold">{item.title}</p>
                <p className=" flex items-center  ">
                  <span className=" text-secondary font-normal  text-xs">
                    {item.offer_price.currency}{' '}
                  </span>
                  <span className=" ml-2">{item.offer_price.amount} </span>
                </p>
                <p className=" flex items-center  text-xs ">
                  <span
                    className={
                      item.offer_percent != 0
                        ? ' line-through text-secondary font-normal'
                        : '  text-secondary font-normal'
                    }
                  >
                    {item.list_price.formatted}
                  </span>
                  <span className=" font-medium ml-2">-{item.offer_percent}%</span>
                </p>
              </div>
               
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Variants
