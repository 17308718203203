import image1 from '@/img/tradly/air-jordan-1-mid-se-shoes-hHltxp.jpeg'
import image2 from '@/img/tradly/jordan-delta-2-shoe-dG2Stx.jpeg'
import image3 from '@/img/tradly/jordan-why-not-zer04-pf-basketball-shoe-P3c3Rp.jpeg'
import image4 from '@/img/tradly/zion-1-pf-basketball-shoe-bHGvxg.jpeg'
 
export const data = [
  {
    id: 9861,
    account_id: 3378,
    list_price: {
      amount: 186.68,
      currency: 'SGD',
      formatted: 'S$186.68',
    },
    category_id: [3430],
    currency_id: 22,
    offer_percent: 0,
    stock: 5,
    shipping_charges: {
      amount: 0,
      currency: 'SGD',
      formatted: 'S$0',
    },
    images: [image1],
    offer_price: {
      amount: 15,
      currency: 'SGD',
      formatted: 'S$15',
    },
    location: {
      city: '',
      state: '',
      country: 'Singapore',
      locality: 'Punggol',
      postcode: '828811',
      country_code: 'sg',
      formatted_address: 'Tebing Lane, Punggol, Northeast, 828811, Singapore',
    },
    type: 'events',
    sold: false,
    active: true,
    status: 2,
    created_at: 1639663205,
    start_at: 1639663205,
    end_at: 1639663205,
    likes: 0,
    max_quantity: 20,
    account: {
      id: 3378,
      name: 'Infiniti Kids',
      location: {
        city: '',
        state: '',
        country: 'Singapore',
        locality: 'Punggol',
        postcode: '828811',
        country_code: 'sg',
        formatted_address: 'Tebing Lane, Punggol, Northeast, 828811, Singapore',
      },
      unique_name: '',
      description:
        'Infiniti Kids aims to inspire children to explore and discover their potential in areas such as sports, music, art and technology so that they become confident, competent and motivated to pursue their area of interest.',
      total_followers: 0,
      total_listings: 3,
      images: [
        'https://tradly-paas.s3.amazonaws.com/images/classbubs/77393/64991909-0dcc-4478-89ed-d39b6e04dd21.JPG',
      ],
      type: 'accounts',
      active: true,
      status: 2,
      following: false,
      user: {
        email_verified: true,
        blocked: false,
        id: 'e9b6c46e-3976-45b9-8f6d-a6bf580dc49d',
        first_name: 'Infiniti',
        last_name: 'Kids',
        profile_pic:
          'https://tradly-paas.s3.amazonaws.com/images/classbubs/35346/2a9043bf-b7a2-44b2-a3e5-5fa5c248046f.JPG',
        email: 'info@infinitikids.org',
        mobile: '',
        dial_code: '',
        created_at: 1639277493,
        updated_at: 1639628165,
      },
      category_id: [],
    },
    distance: 0,
    in_cart: false,
    liked: false,
    title: 'AIR JORDAN 1 MID SE',
    description:
      'About educator: Infiniti Kids aims to inspire children to explore and discover their potential in areas such as sports, music, art and technology so that they become confident, competent and motivated to pursue their area of interest.\n\nAbout class: Run, Jump, Throw! Infiniti Kids Multi Sports class aims to promote and develop Physical Literacy in young children, to develop them to be confident, competent and motivated in physical activity.\nOur program:\n\n Takes them through a variety of sports to allow sampling and exploration for kids to find\nwhat they truly enjoy!\n Consists of activities such as obstacle courses, circuits, fun games and sport skill\npractice, developing their fitness, fundamental movement skills and basic sports skills to\nprepare them for future sporting activities.\n Builds confidence and develop values in your child through social interactions with the\ncoaches and other children.\n\nJoin us for a fun and sweaty time!\n\nAge group: 5-7\n\nLocation: 6 Tebing Lane, S(828835)',
    coordinates: {
      latitude: 1.3929445,
      longitude: 103.9152788,
    },
  },
  {
    id: 9860,
    account_id: 3378,
    list_price: {
      amount: 208.44,
      currency: 'SGD',
      formatted: 'S$208.44',
    },
    category_id: [3430],
    currency_id: 22,
    offer_percent: 0,
    stock: 5,
    shipping_charges: {
      amount: 0,
      currency: 'SGD',
      formatted: 'S$0',
    },
    images: [image2],
    offer_price: {
      amount: 15,
      currency: 'SGD',
      formatted: 'S$15',
    },
    location: {
      city: '',
      state: '',
      country: 'Singapore',
      locality: 'Punggol',
      postcode: '828811',
      country_code: 'sg',
      formatted_address: 'Tebing Lane, Punggol, Northeast, 828811, Singapore',
    },
    type: 'events',
    sold: false,
    active: true,
    status: 2,
    created_at: 1639662894,
    start_at: 1639662894,
    end_at: 1639662894,
    likes: 0,
    max_quantity: 20,
    account: {
      id: 3378,
      name: 'Infiniti Kids',
      location: {
        city: '',
        state: '',
        country: 'Singapore',
        locality: 'Punggol',
        postcode: '828811',
        country_code: 'sg',
        formatted_address: 'Tebing Lane, Punggol, Northeast, 828811, Singapore',
      },
      unique_name: '',
      description:
        'Infiniti Kids aims to inspire children to explore and discover their potential in areas such as sports, music, art and technology so that they become confident, competent and motivated to pursue their area of interest.',
      total_followers: 0,
      total_listings: 3,
      images: [image2],
      type: 'accounts',
      active: true,
      status: 2,
      following: false,
      user: {
        email_verified: true,
        blocked: false,
        id: 'e9b6c46e-3976-45b9-8f6d-a6bf580dc49d',
        first_name: 'Infiniti',
        last_name: 'Kids',
        profile_pic:
          'https://tradly-paas.s3.amazonaws.com/images/classbubs/35346/2a9043bf-b7a2-44b2-a3e5-5fa5c248046f.JPG',
        email: 'info@infinitikids.org',
        mobile: '',
        dial_code: '',
        created_at: 1639277493,
        updated_at: 1639628165,
      },
      category_id: [],
    },
    distance: 0,
    in_cart: false,
    liked: true,
    title: 'JORDAN DELTA 2',
    description:
      'About educator: Infiniti Kids aims to inspire children to explore and discover their potential in areas such as sports, music, art and technology so that they become confident, competent and motivated to pursue their area of interest.\n\nAbout class: Run, Jump, Throw! Infiniti Kids Multi Sports class aims to promote and develop Physical Literacy in young children, to develop them to be confident, competent and motivated in physical activity.\nOur program:\n\n Takes them through a variety of sports to allow sampling and exploration for kids to find\nwhat they truly enjoy!\n Consists of activities such as obstacle courses, circuits, fun games and sport skill\npractice, developing their fitness, fundamental movement skills and basic sports skills to\nprepare them for future sporting activities.\n Builds confidence and develop values in your child through social interactions with the\ncoaches and other children.\n\nJoin us for a fun and sweaty time!\n\nAge group: 3-5\n\nLocation: 6 Tebing Lane, S(828835)',
    coordinates: {
      latitude: 1.3929445,
      longitude: 103.9152788,
    },
  },
  {
    id: 9859,
    account_id: 3378,
    list_price: {
      amount: 208.44,
      currency: 'SGD',
      formatted: 'S$208.44',
    },
    category_id: [3430],
    currency_id: 22,
    offer_percent: 0,
    stock: 5,
    shipping_charges: {
      amount: 0,
      currency: 'SGD',
      formatted: 'S$0',
    },
    images: [image3],
    offer_price: {
      amount: 190,
      currency: 'SGD',
      formatted: 'S$208.44',
    },
    location: {
      city: 'Singapore',
      state: '',
      country: 'Singapore',
      locality: 'Geylang',
      postcode: '409961',
      country_code: 'sg',
      formatted_address:
        'LTC Industrial Building D, 16, Arumugam Road, Geylang, Singapore, Southeast, 409961, Singapore',
    },
    type: 'events',
    sold: false,
    active: true,
    status: 2,
    created_at: 1639662715,
    start_at: 1639662715,
    end_at: 1639662715,
    likes: 0,
    max_quantity: 20,
    account: {
      id: 3378,
      name: 'Infiniti Kids',
      location: {
        city: '',
        state: '',
        country: 'Singapore',
        locality: 'Punggol',
        postcode: '828811',
        country_code: 'sg',
        formatted_address: 'Tebing Lane, Punggol, Northeast, 828811, Singapore',
      },
      unique_name: '',
      description:
        'Infiniti Kids aims to inspire children to explore and discover their potential in areas such as sports, music, art and technology so that they become confident, competent and motivated to pursue their area of interest.',
      total_followers: 0,
      total_listings: 3,
      images: [
        'https://tradly-paas.s3.amazonaws.com/images/classbubs/77393/64991909-0dcc-4478-89ed-d39b6e04dd21.JPG',
      ],
      type: 'accounts',
      active: true,
      status: 2,
      following: false,
      user: {
        email_verified: true,
        blocked: false,
        id: 'e9b6c46e-3976-45b9-8f6d-a6bf580dc49d',
        first_name: 'Infiniti',
        last_name: 'Kids',
        profile_pic:
          'https://tradly-paas.s3.amazonaws.com/images/classbubs/35346/2a9043bf-b7a2-44b2-a3e5-5fa5c248046f.JPG',
        email: 'info@infinitikids.org',
        mobile: '',
        dial_code: '',
        created_at: 1639277493,
        updated_at: 1639628165,
      },
      category_id: [],
    },
    distance: 0,
    in_cart: false,
    liked: true,
    title: "JORDAN 'WHY NOT?' ZER0.4 PF",
    description:
      'About educator: Infiniti Kids aims to inspire children to explore and discover their potential in areas such as sports, music, art and technology so that they become confident, competent and motivated to pursue their area of interest.\n\nAbout class: Run, Jump, Throw! Infiniti Kids Multi Sports class aims to promote and develop Physical Literacy in young children, to develop them to be confident, competent and motivated in physical activity.\nOur program:\n\n Takes them through a variety of sports to allow sampling and exploration for kids to find\nwhat they truly enjoy!\n Consists of activities such as obstacle courses, circuits, fun games and sport skill\npractice, developing their fitness, fundamental movement skills and basic sports skills to\nprepare them for future sporting activities.\n Builds confidence and develop values in your child through social interactions with the\ncoaches and other children.\n\nJoin us for a fun and sweaty time!\n\nAge group: 3-5\n\nLocation: 16 Arumugam Rd, Singapore 409961',
    coordinates: {
      latitude: 1.3253893,
      longitude: 103.8915179,
    },
  },
  {
    id: 9858,
    account_id: 3380,
    list_price: {
      amount: 186.68,
      currency: 'SGD',
      formatted: 'S$186.68',
    },
    category_id: [3421],
    currency_id: 22,
    offer_percent: 0,
    stock: 5,
    shipping_charges: {
      amount: 0,
      currency: 'SGD',
      formatted: 'S$0',
    },
    images: [image4],
    offer_price: {
      amount: 65,
      currency: 'SGD',
      formatted: 'S$65',
    },
    location: {
      city: 'Singapore',
      state: '',
      country: 'Singapore',
      locality: 'Queenstown',
      postcode: '138694',
      country_code: 'sg',
      formatted_address:
        'Centre Stage Scool of the Arts, 15, Woking Road, Nepal Hill, Queenstown, Singapore, Central, 138694, Singapore',
    },
    type: 'events',
    sold: false,
    active: true,
    status: 2,
    created_at: 1639662164,
    start_at: 1639662163,
    end_at: 1639662163,
    likes: 0,
    max_quantity: 20,
    account: {
      id: 3380,
      name: 'Centre Stage School of the Arts',
      location: {
        city: 'Singapore',
        state: '',
        country: 'Singapore',
        locality: 'Queenstown',
        postcode: '138694',
        country_code: 'sg',
        formatted_address:
          'Centre Stage Scool of the Arts, 15, Woking Road, Nepal Hill, Queenstown, Singapore, Central, 138694, Singapore',
      },
      unique_name: '',
      description:
        'We have been providing workshops for all ages in Singapore since 1999, with a team of specialized, performers, educators, and teachers, passionate about the Arts, Creativity, Communication & Performance. We deliver Theatre in Education Drama, Movement, Dance, Singing, Performance Workshops for Children & Adults. As well as Teacher Training Workshops, Corporate Training, Creative Arts Workshops, Interactive Storytelling with follow up workshops.',
      total_followers: 0,
      total_listings: 6,
      images: [
        'https://tradly-paas.s3.amazonaws.com/images/classbubs/28970/b8b258a2-6034-4e24-8f6f-526eecc32f94.PNG',
      ],
      type: 'accounts',
      active: true,
      status: 2,
      following: false,
      user: {
        email_verified: true,
        blocked: false,
        id: '9a6662be-2010-4b96-bb5c-4a5fefc17aef',
        first_name: 'Centre Stage',
        last_name: 'School Of The Arts',
        profile_pic:
          'https://tradly-paas.s3.amazonaws.com/images/classbubs/62791/2987fcf7-67c7-4517-bc6a-27b6ba02c685.PNG',
        email: 'nadine@centre-stage.com',
        mobile: '',
        dial_code: '',
        created_at: 1639448154,
        updated_at: 1639628396,
      },
      category_id: [],
    },
    distance: 0,
    in_cart: false,
    liked: false,
    title: 'Zion 1 PF',
    description:
      'About educator: We have been providing workshops for all ages in Singapore since 1999, with a team of specialized, performers, educators, and teachers, passionate about the Arts, Creativity, Communication & Performance. We deliver Theatre in Education Drama, Movement, Dance, Singing, Performance Workshops for Children & Adults. As well as Teacher Training Workshops, Corporate Training, Creative Arts Workshops, Interactive Storytelling with follow up workshops.\n\nAbout class: Please note that class level assignment will be at the sole discretion of the Ballet Mistress.\n\nThe Graded Examination Syllabus consists of three different components: classical, free movement and character dance.\n\nClassical: the foundation and most important part of the Graded Syllabi.\nFree movement: incorporates movements in common with other dance styles such as natural movement, contemporary based dance and Greek dance.\nCharacter dance: is the theatrical presentation of national dance using original ethnic dance and music that has been freely adapted for the theatre. There are three styles – Hungarian, Russian and Polish – and these were selected because of their historic importance in the development of the traditional full-length classical ballets.\n\nAge group: 7+\n\nLocation: 15 Woking Rd',
    coordinates: {
      latitude: 1.29651935,
      longitude: 103.7941889775051,
    },
  },
]