import React, { useState } from 'react'
import ImagePart from './ListingDetails/ImagePart/ImagePart'
import { event } from './data/ListingDetailsData'
import MainBox from './ListingDetails/MainBox/MainBox'
import Variants from './ListingDetails/Variants/Variants'
import ProductButtons from './ListingDetails/ProductButtons/ProductButtons'
import StoreNameBox from './ListingDetails/StoreNameBox/StoreNameBox'
import DescriptionPart from './ListingDetails/DescriptionPart/DescriptionPart'
import AddressBox from './ListingDetails/AddressBox/AddressBox'
import EventButtons from './ListingDetails/EventsButtons/EventButtons'
import classes from './common.module.scss'
import Image from "next/image"

const Event_feature = () => {
  const listing_details = event.listing
  const rating_data = event.rating_data
  // const [selectedVariant, setSelectedVariant] = useState()

  return (
    <div className={classes.bg_style} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <div className=" grid md:grid-cols-2 gap-5   sm:p-5 lg:p-10 ">
        <div className="  ">
          <div className=" relative aspect-square">
            <Image
              src={listing_details?.images[0]}
              alt="details image"
              layout="fill"
              objectFit="cover"
              priority={true}
            />
          </div>
        </div>
        <div className="  mt-6 md:mt-0 ">
          <div className="    shadow-lg">
            <MainBox
              listing_details={listing_details}
              rating_data={rating_data}
              marketplace_type={2}
            />
          </div>
          {listing_details?.variants?.length > 0 && (
            <div className="mt-6 shadow-lg">
              <Variants
                variants={listing_details.variants}
                listing_details={listing_details}
                marketplace_type={2}
              />
            </div>
          )}
          <div className="mt-6 shadow-lg">
            <EventButtons listing_details={listing_details} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Event_feature
