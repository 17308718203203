/* eslint-disable react/prop-types */
import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

const StoreNameBox = ({ account, marketplace_type }) => {
  return (
    <div className=" bg-white rounded  w-full  min-h-[81px] flex   justify-between items-center  p-[16px] shadow-c-sm">
      <div className="flex items-center">
        <div className="flex justify-start items-start w-[32px] h-[32px] rounded-full overflow-hidden relative">
          <Image src={account?.images[0]} alt="store Image" layout="fill" objectFit="cover" />
        </div>

        <a
          className=" block ml-[10px] cursor-pointer "
          // onClick={() =>
          //   router.push({
          //     pathname: '/a/[id]',
          //     query: {
          //       id: `${account?.id}-${account?.name.replace(/\s/g, '-')}`,
          //       page: 1,
          //     },
          //   })
          // }
        >
          <p className="text-base leading-4 text-[#121212] font-medium">{account?.name}</p>
        </a>
      </div>
      <div className="  flex justify-center  float-right">
        <button
          className={[
            '  rounded w-[80px] h-[30px] flex justify-center items-center  text-xs leading-3 font-semibold border border-primary',
            account.following ? 'bg-primary text-white ' : 'bg-transparent text-primary ',
          ].join(' ')}
        >
          {account.following ? 'Following' : 'Follow'}
        </button>
      </div>
    </div>
  )
}

export default StoreNameBox;
