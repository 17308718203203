/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useRouter } from 'next/dist/client/router'

const ProductButtons = ({ listing_details }) => {
  return (
    <>
      <div className=" w-full flex justify-between items-center">
        <button className="   w-[45%] h-[52px]  flex justify-center items-center border border-primary rounded-lg box-border  ">
          <span className=" text-base md:text-xl text-primary font-semibold ml-3">Add To Cart</span>
        </button>
        <button
          className="  w-[50%]  h-[52px] bg-primary rounded-lg flex justify-center items-center "
          // onClick={add_to_Cart}
        >
          <span className=" test-base md:text-xl text-white font-semibold  ">Buy Now</span>
        </button>
      </div>
    </>
  )
}

export default ProductButtons
