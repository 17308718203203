import { Steps } from '@/components/Steps'

export default function ApiReferenceFeature() {
  let steps = [
    {
      title: 'Base URLs',
      body: () => <p>You can access Tradly API via one of the below endpoints.</p>,
      code: {
        name: 'api.js',
        lang: 'js',
        code: `Production: https://api.tradly.app`,
      },
    },
    {
      title: 'Authentication',
      body: () => (
        <p>
          In order to access APIs, you will need a valid API KEY. This key is generated when your
          sign up is complete and you can always access this via the Superadmin Panel Get your
          API keys from https://tradly.app/signup
        </p>
      ),
      code: {
        name: '🌐https://developer.tradly.app/',
        lang: 'web',
        code: `Once signed up, go to Settings > API

Tradly offers two different keys: publishable key and secret key

secret key: access to all API endpoints including admin related endpoints. Endpoints that can be accessed only by secret key are marked as [ADMIN].
publishable key: access to all endpoints other than admin endpoints.
All requests need API KEY in the request header as Bearer token as shown below,

Authorization: Bearer pk_test_60cc337410424b0abfbc0332e49c

`,
      },
    },
    {
      title: 'Example',
      body: () => <p> Start with tradly api reference and enjoy 🎉</p>,
      code: {
        name: 'api.js',
        lang: 'js',
        code: `var axios = require('axios');
var data = '';

var config = {
  method: 'get',
  url: 'https://api.tradly.app/v1/categories?parent={{parent_id}}&type={{type}}',
  headers: { 
    'Content-Type': 'application/json'
  },
  data : data
};

axios(config)
.then(function (response) {
  console.log(JSON.stringify(response.data));
})
.catch(function (error) {
  console.log(error);
});`,
      },
    },
  ]

  let code = steps.map(({ code }) => {
    let isArray = Array.isArray(code)
    code = isArray ? code : [code]
    code = code.map((code) => {
      return code.code
    })
    return isArray ? code : code[0]
  })
  return (
    <div className="relative h-[500px] overflow-auto">
      <Steps steps={steps} code={code} />
    </div>
  )
}
