


import React, { useState } from 'react'
import { Widont } from '../../Widont'
import { BigText, Caption, IconContainer as div, Link, Paragraph } from '../common'
import iconUrl from '@/img/icons/home/tradly-image.png'
import { Tab } from '@headlessui/react'
import heroIcon from '@/img/tradly/hero_icons/launchwhatever.svg'
import { feature1 } from '../tradlyFeatures/feature'
import Product_feature from '../tradlyFeatures/product_feature'
import Event_feature from '../tradlyFeatures/Event_feature'
import Community_feature from '../tradlyFeatures/Community_feature'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Modularity = () => {
  let [categories] = useState([
    {
      id: 1,
      buttonTitle: 'Product',
      icon: (
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
        />
      ),
      feature: Product_feature,
    },
    {
      id: 2,
      buttonTitle: 'Event',
      icon: (
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
        />
      ),
      feature: Event_feature,
    },
    {
      id: 3,
      buttonTitle: 'Community',
      icon: (
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
        />
      ),
      feature: Community_feature,
    },
    // {
    //   id: 4,
    //   buttonTitle: 'Directory ',
    //   icon: (
    //     <path
    //       stroke-linecap="round"
    //       stroke-linejoin="round"
    //       stroke-width="2"
    //       d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
    //     />
    //   ),
    //   feature: feature1,
     // },
  ])

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 ">
      <div>
        <img src={heroIcon} alt="" />
      </div>
      <Caption className="text-color4">Modularity is our mindset</Caption>
      <BigText>
        <Widont>Launch whatever you want.</Widont>
      </BigText>
      <Paragraph>
      Everyone has a unique dream. Our Builder empowers you to create without limits—craft Marketplaces, Commerce, Community Platforms, Discovery Apps, A User-generated directory, or SaaS Partner Portals effortlessly.
      </Paragraph>
      {/* <Link href="/" color="indigo">
        Learn more<span className="sr-only">, about tradly</span>
      </Link> */}

      <div className="w-full    sm:px-0">
        <Tab.Group>
          <Tab.List className=" mt-10 flex p-1  items-center gap-7 ">
            {categories.map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  classNames(
                    'w-[96px] py-2.5 text-sm leading-5 font-medium rounded-lg flex flex-col justify-center items-center gap-6  group outline-none',
                    selected
                      ? ' transition duration-500 text-color4'
                      : 'text-gray-300  hover:text-gray-400'
                  )
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-12 w-12"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  {category.icon}
                </svg>
                {/* <img className=" text-red-300:" src={category.icon} alt="" /> */}
                <span className=" ">{category.buttonTitle}</span>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="pt-10 xl:pt-0  max-h-[500px] overflow-auto  md:max-h-full  mt-2 ">
            {categories.map((post, idx) => (
              <Tab.Panel
                key={idx}
                className={classNames('bg-white rounded-xl  md:p-3', 'focus:outline-none  ')}
              >
                {post?.feature}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  )
}

export default Modularity
