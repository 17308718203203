import React from 'react';
import CheckoutPageLayout from './CheckoutPage/CheckoutPageLayout';
import classes from "./common.module.scss"

const CommerceFeature = () => {
    const style = {
        
    }
    return (
        <div className={classes.bg_style}>
            <CheckoutPageLayout/>
        </div>
    );
};

export default CommerceFeature;