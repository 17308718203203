import React from 'react'
import Image from 'next/image'
import demoImage1 from '@/img/tradly/Turkish Dried Fruits.jpeg'
import demoImage2 from '@/img/tradly/Yemen coffee.jpeg'
import demoImage3 from "@/img/tradly/Organic Africa's Veg.jpg"
import demoImage4 from '@/img/tradly/Nordic Flowers.jpeg'

const MultipleAccounts = () => {
  const data = [
    {
      id: 1,
      shop_name: 'Turkish Fruits',
      account_name: 'Artogul',
      image: demoImage1,
    },
    {
      id: 1,
      shop_name: 'Yemen Coffee ',
      account_name: 'Tushar',
      image: demoImage2,
    },
    {
      id: 1,
      shop_name: 'Dutch Vegs',
      account_name: 'Akabir',
      image: demoImage3,
    },
    {
      id: 1,
      shop_name: ' Nordic Flowers',
      account_name: 'Polash',
      image: demoImage4,
    },
  ]
  return (
    <div className=" max-h-[490px] overflow-auto  grid grid-cols-2	 content-center    md:p-10 md:flex md:flex-wrap justify-center items-center gap-5">
      {data.map((item, index) => {
        return (
          <div className=" relative w-full md:w-[200px]   transition duration-500 transform hover:scale-110 ">
            <a className=" block  w-full min-h-[166px]   p-2 bg-[#FEFEFE] rounded-lg shadow-md cursor-pointer">
              <div className=" rounded  overflow-hidden relative mx-auto  aspect-w-1 aspect-h-1">
                <Image src={item.image} alt="image" layout="fill" objectFit="cover" />
              </div>
              <div className="mt-2 mb-2">
                <p className="w-full text-[#000000] font-semibold text-[14px] leading-4   text-left ">
                  {item.shop_name}
                </p>
              </div>
              <div className="flex justify-start  items-center mb-2 relative">
                <p className="w-full text-[#4F4F4F] font-medium text-[14px]  text-left  ">
                  {item.account_name}
                </p>
              </div>
            </a>

            <div className=" absolute bottom-0 right-0  mb-4 mr-2">
              <button
                className={
                  ' px-2 py-[6px] text-[10px] leading-3 items-center rounded-full border border-primary  text-primary'
                }
                // onClick={() => setFollow(!follow)}
              >
                Follow
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default MultipleAccounts
