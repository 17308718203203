import React, { useState } from 'react'
import demoImage1 from '@/img/tradly/account1.png'
import banner from '@/img/tradly/banner.png'
import Image from 'next/image'
import MultipleAccounts from '../Marketplace/MultipleAccounts'
import MultipleListings from '../MultipleListings'

const AccountDetails = () => {
  const [follow, setFollow] = useState(false)

  const data = [
    // {
    //   title: 'Description',
    //   value: account_details?.description,
    //   extra_style: true,
    // },
    {
      title: 'Categories',
      value: 'Table & Chair',
      extra_style: false,
    },
    {
      title: 'Location',
      value: 'Malashiya' || 'N/A',
      extra_style: false,
    },
    { title: 'Total Followers', value: 100 },
    {
      title: 'Total Listings',
      value: 50,
      extra_style: false,
    },
  ]

  return (
    <div className="max-w-[600px] mx-auto">
      <div className="w-full relative ">
        <div className=" relative  w-full  min-h-[126px] px-[10px] sm:px-[40px] py-[20px] bg-white    rounded-[10px] shadow-lg ">
          <div className=" flex items-center">
            <div className=" w-[60px] h-[60px] rounded-full overflow-hidden  relative   mr-6 ">
              <Image src={demoImage1} layout="fill" objectFit="cover" alt="banner" />
            </div>
            <div className=" flex flex-col items-start">
              <p className=" font-semibold text-base text-black"> Jay Z shop</p>
              <p className=" text-secondary text-sm">@jay</p>
            </div>
          </div>

          <div className=" grid grid-cols-3 items-center justify-between  divide-x">
            <div
              className=" h-[50px]  flex justify-center  
            "
            >
              <p className="flex items-center gap-1">
                0.0{' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="#ff9529"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>
              </p>
            </div>
            <div className="  flex flex-col items-center text-center">
              <p className="font-semibold text-base text-gray-900  text-center"> 30</p>
              <p>Total Products</p>
            </div>
            <div className=" flex flex-col items-center border-l-2 border-gray-400 border-opacity-70 ">
              <p className=" font-semibold text-base text-gray-900 ">100</p>
              <p>Followers</p>
            </div>
          </div>
          <div className=" mt-5    grid grid-cols-3   ">
            <button
              className={[
                'px-2  md:w-[145px] h-[35px] flex justify-center items-center border border-primary  rounded-lg mx-auto',
                follow ? 'text-[#FFFFFF]  bg-primary' : 'text-primary   bg-transparent',
              ].join(' ')}
              onClick={() => setFollow(!follow)}
            >
              {follow ? 'Following' : 'Follow'}
            </button>
            <button
              className={
                'px-2  md:w-[145px] h-[35px] flex justify-center items-center border border-primary  rounded-lg text-primary   mx-auto'
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                />
              </svg>
              Share
            </button>
            <button
              className={[
                ' px-2  md:w-[145px] h-[35px] flex justify-center items-center border border-primary  rounded-lg   text-primary mx-auto',
              ].join(' ')}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                />
              </svg>
              Chat
            </button>
          </div>
        </div>
        <div className="   mt-5 rounded-xl shadow-c-xsm ">
          <MultipleListings />
        </div>
      </div>
    </div>
  )
}

export default AccountDetails
