import React, { useState } from 'react'
import Image from 'next/image'
import demoImage1 from '@/img/beach-house-interior-2.jpg'
import AccountDetails from './AccountDetails';
import classes from '../common.module.scss'

 
const SocialFeature = () => {
  const showFollow = false;
  return (
    <div className={classes.bg_style}>
      {/* <div className=" relative w-[200px]  transition duration-500 transform hover:scale-110"> */}
      {/* <button>
          <a className=" block w-[200px] min-h-[166px]   p-2 bg-[#FEFEFE] rounded-lg shadow-md cursor-pointer">
            <div className=" rounded  overflow-hidden relative mx-auto  aspect-w-1 aspect-h-1">
              <Image src={demoImage1} alt="image" layout="fill" objectFit="cover" />
            </div>
            <div className="mt-2 mb-2">
              <p className="w-full text-[#000000] font-semibold text-[14px] leading-4   text-left ">
                Apollo House
              </p>
            </div>
            <div className="flex justify-start  items-center mb-2 relative">
              <p className="w-full text-[#4F4F4F] font-medium text-[14px]  text-left  ">
                Alp Arsalan
              </p>
            </div>
          </a>
        </button>

        <div className=" absolute bottom-0 right-0  mb-4 mr-2">
          <button
            className={[
              ' px-2 py-[6px] text-[10px] leading-3 items-center rounded-full border border-primary ',
              showFollow ? 'text-[#FFFFFF]  bg-primary' : 'text-primary   bg-transparent',
            ].join(' ')}
            onClick={() => setShowFollow(!follow)}
          >
            {showFollow ? 'Following' : 'Follow'}
          </button>
        </div>
      </div> */}
      <div className="   md:p-10 max-h-[450px] overflow-auto ">
        <AccountDetails />
      </div>
    </div>
  )
}

export default SocialFeature
