import { Steps } from '@/components/Steps'

export default function JsSdkFeature() {
  let steps = [
    {
      title: 'Install TradlyJS SDK ',
      body: () => (
        <p>
          Install <code>JS SDK</code> via npm.
        </p>
      ),
      code: {
        name: 'Terminal',
        lang: 'terminal',
        code: 'npm install  tradly',
      },
    },
    {
      title: 'Initialization of SDK',
      body: () => (
        <p>
          Add your tradly token and environment name in your <code>app.js</code> file.
        </p>
      ),
      code: {
        name: 'app.js',
        lang: 'js',
        code: `import TradlySDK from 'tradly'; 
TradlySDK.init.config({token:'',environment:'production'})`,
      },
    },
    {
      title: 'Example',
      body: () => <p> Start with tradly JS SDK and enjoy 🎉</p>,
      code: {
        name: 'app.js',
        lang: 'js',
        code: `const [error, responseJson]  = await TradlySDK.app.getCategory({bodyParam:{'type':'listings'},authKey:'authkey'})
        `,
      },
    },
  ]

  let code = steps.map(({ code }) => {
    let isArray = Array.isArray(code)
    code = isArray ? code : [code]
    code = code.map((code) => {
      return code.code
    })
    return isArray ? code : code[0]
  })
    return (
      <div className="relative  h-[500px] overflow-auto">
        <Steps steps={steps} code={code} />
      </div>
    )
}
