import React from 'react';
import MultipleAccounts from './Marketplace/MultipleAccounts';
import classes from './common.module.scss'


const MarketplaceFeature = () => {
    return (
      <div className={classes.bg_style}>
        <MultipleAccounts />
      </div>
    )
};

export default MarketplaceFeature;