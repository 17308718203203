import React, { useState } from 'react'
import ImagePart from './ListingDetails/ImagePart/ImagePart'
import { product } from './data/ListingDetailsData'
import MainBox from './ListingDetails/MainBox/MainBox'
import Variants from './ListingDetails/Variants/Variants'
import ProductButtons from './ListingDetails/ProductButtons/ProductButtons'
import StoreNameBox from './ListingDetails/StoreNameBox/StoreNameBox'
import DescriptionPart from './ListingDetails/DescriptionPart/DescriptionPart'
import AddressBox from './ListingDetails/AddressBox/AddressBox'
import classes from './common.module.scss'

const Product_feature = () => {
  const listing_details = product.listing
  const rating_data = product.rating_data

  return (
    <div className={classes.bg_style} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
      <div className="grid   md:grid-cols-2 gap-5     lg:p-10">
        <div className=" ">
          <div className=" ">
            <img
              className=" aspect-square"
              src={listing_details?.images[0]}
              alt="details image"
              layout="fill"
              objectFit="cover"
              priority={true}
            />
          </div>
        </div>
        <div className=" mt-6 md:mt-0">
          <div className=" shadow-lg">
            <MainBox
              listing_details={listing_details}
              rating_data={rating_data}
              marketplace_type={1}
            />
          </div>

          <div className="mt-6 shadow-lg">
            <ProductButtons listing_details={listing_details} marketplace_type={1} />
          </div>
          {listing_details?.account && (
            <div className="mt-6 shadow-lg">
              <StoreNameBox account={listing_details?.account} />
            </div>
          )}
          {listing_details?.location && Object.keys(listing_details?.location).length > 0 && (
            <div className="mt-6 shadow-lg">
              <AddressBox location={listing_details?.location} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Product_feature
